const $ = jQuery.noConflict();

export const enhancer = () => {

  // General
  $('.select-wrapper select').each(function() {
    var selectedOptionText = $(this).find('option:selected').text();
    $(this).parent().find('span').text(selectedOptionText);
  });

  $('.select-wrapper select').change(function() {
    var selectedOptionText = $(this).find('option:selected').text();
    $(this).parent().find('span').text(selectedOptionText);
  });

  var currentPage = document.location.protocol + window.location.hostname + window.location.pathname + '?';
  var redirectPage;

  // Calendar
  $('.filter select.event_genre, .filter input.event_family_concert').change(function() {

    redirectPage = currentPage;

    // Select: Genre
    if ($('.filter select.event_genre').find('option:selected').val()) {
      redirectPage += 'event_genre=' + $('.filter select.event_genre').find('option:selected').val() + '&';
    }

    // Toggle: Family concert
    if ($('.filter input.event_family_concert').is(":checked")) {
      redirectPage += 'event_family_concert=' + $('.filter input.event_family_concert').val();
    }

    redirectPage += '#filter';
    window.location.href = redirectPage;

  });

  // Repertoire
  $('.filter select.repertoire_composer, .filter select.repertoire_arranger, .filter select.repertoire_genre, .filter input.sheetmusic, .filter input.cd').change(function() {

    redirectPage = currentPage;

    // Select: repertoire_composer
    if ($('.filter select.repertoire_composer').find('option:selected').val()) {
      redirectPage += 'repertoire_composer=' + $('.filter select.repertoire_composer').find('option:selected').val() + '&';
    }

    // Select: repertoire_arranger
    if ($('.filter select.repertoire_arranger').find('option:selected').val()) {
      redirectPage += 'repertoire_arranger=' + $('.filter select.repertoire_arranger').find('option:selected').val() + '&';
    }

    // Select: repertoire_genre
    if ($('.filter select.repertoire_genre').find('option:selected').val()) {
      redirectPage += 'repertoire_genre=' + $('.filter select.repertoire_genre').find('option:selected').val() + '&';
    }

    // Toggle: sheetmusic
    if ($('.filter input.sheetmusic').is(":checked")) {
      redirectPage += 'sheetmusic=' + $('.filter input.sheetmusic').val() + '&';
    }

    // Toggle: cd
    if ($('.filter input.cd').is(":checked")) {
      redirectPage += 'cd=' + $('.filter input.cd').val();
    }

    redirectPage += '#filter';
    window.location.href = redirectPage;

  });

  $('.filter select.event_year').change(function() {
    window.location.href = $(this).val();
  });

};
