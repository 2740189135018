import domready from 'domready';
import fitvids from 'fitvids';

import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as scrollToElement } from './modules/scroll-to-element';
import { handler as googleAnalyticsEventHandler } from './modules/google-analytics';

// Import enhancers
import { enhancer as googleAnalyticsEventEnhancer } from './modules/google-analytics';
import { enhancer as header } from './modules/header';
import { enhancer as stickyHeader } from './modules/sticky-header';
import { enhancer as search } from './modules/search';
import { enhancer as maps } from './modules/maps';
import { enhancer as filter } from './modules/filter';
import { enhancer as education } from './modules/education';
import { enhancer as discoveryHeader } from './modules/discovery-header';
import { enhancer as discoveryIntro } from './modules/discovery-intro';


const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  fitvids('.flexible-content__item.embed');
  fitvids('.homepage-intro__section--embed');

  /**
   * Simple requestAnimationFrame
   * @see http://elektronotdienst-nuernberg.de/bugs/requestAnimationFrame.html
   */
  window.requestAnimationFrame = window.requestAnimationFrame || function(c) {
    return setTimeout(() => {
      c(+new Date);
    }, 30);
  };
};

function main() {
  executeOnReady();

  handle({
    classToggler,
    scrollToElement,
    googleAnalyticsEventHandler,
  });

  enhance({
    googleAnalyticsEventEnhancer,
    header,
    stickyHeader,
    search,
    maps,
    filter,
    education,
    discoveryHeader,
    discoveryIntro,
  });
}

const $ = jQuery.noConflict();
$(() => {
  main();
});
