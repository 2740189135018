import {
  isVisible as sectionIsVisible,
  isScrolledPast as sectionIsScrolledPast,
  register as registerScrollListener
} from "./scroll-listener";

const scrolledPastScrollEl = (el) => {
  const scrollPastIdentifier = el.getAttribute('data-scroll-past');
  const scrollPastEl = document.querySelector(scrollPastIdentifier);
  const explanationEl = document.querySelector('.hidden-gems-discovery-header__explanation');

  registerScrollListener('introHeaderListener', () => {
    explanationEl.classList.add('is-hidden', sectionIsScrolledPast(el));
    scrollPastEl.classList.toggle('is-hidden', sectionIsVisible(el));
    scrollPastEl.classList.toggle('is-visible', !sectionIsVisible(el));
  });
}

export const enhancer = el => {
  window.addEventListener('load', e => scrolledPastScrollEl(el));
};
