import {
  isScrolledPast as sectionIsScrolledPast,
  register as registerScrollListener
} from "./scroll-listener";

const toggleClassOnScroll = el => {
  const scrollPastIdentifier = el.getAttribute('data-scroll-past');
  const scrollPastElement = document.querySelector(scrollPastIdentifier);

  registerScrollListener('stickyHeaderListener', () => {
    el.classList.toggle('is-sticky', sectionIsScrolledPast(scrollPastElement))
  });
}

export const enhancer = el => {
  toggleClassOnScroll(el);
};
