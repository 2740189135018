const $ = jQuery.noConflict();

export const enhancer = () => {
  $('.navigation__item__button--search').click(function(e) {
    $('.search-form').removeClass('is-hidden');
    $('.search-form input[type=text]').focus();
    e.preventDefault();
    e.stopPropagation();
  });

  $('.search-form input').click(function(e) {
    e.stopPropagation();
  });

  $('body').click(function(e) {
    $('.search-form').addClass('is-hidden');
  });
};
