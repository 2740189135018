const TIMEOUT = 2200;

/**
 * Show or hide scroll down elemen t
 */
const toggleScrollDownEl = (scrollDownEl) => {
  scrollDownEl.classList.add('is-visible');
};


/**
 * Hide hint
 */
const hideHint = (hint) => {
  hint.classList.add('is-hidden');
  hint.classList.remove('is-visible');
};

/**
 * Show hint
 */
const showHint = (hint) => {
  hint.classList.add('is-visible');
  hint.classList.remove('is-hidden');
};


/**
  * Show or hide explanation hint
  */
const toggleExplanationEl = (scrollDownEl, explanationEl, html) => {
  if (html.classList.contains('touchevents')) {
    showHint(explanationEl);
    hideHint(scrollDownEl);
  }
};

/**
 * Toggle class to position header
 */
const manipulateDocument = (el, html) => {
  html.classList.toggle('has-fixed-discovery-header', el.classList.contains('hidden-gems-discovery-header--has-content'));
};


/**
  * Inspired by a CodePen by Diaco m.lotfollahi: https://diacodesign.com
  * https://codepen.io/MAW/pen/bpQjGY
  */
const trailingCursor = (el, scrollDownEl, explanationEl) => {
  const path = el.querySelector('.hidden-gems-discovery-header__path');
  const amountPoints = 20;

  const pM = 'M'; // Path - Move to
  const pQ = ' Q'; // Path - Quadratic curve
  const pCo = ','; // Path - Comma
  const pSp = ' '; // Path - Space
  const pDZ = '0,0'; // Path - Draw Coordinates
  const pD = [] ;

  const scrollPastIdentifier = el.getAttribute('data-scroll-past');
  const scrollPastElement = document.querySelector(scrollPastIdentifier);
  const hintEl = el.querySelector('.hidden-gems-discovery-header__hint');

  /**
   * Mouse events
   */
  document.addEventListener('mousemove', e => {
    hideHint(hintEl);
    addPoint(e);
  });

  document.addEventListener('mouseleave', e => {
    setTimeout(function() {
      showHint(hintEl);
    }, TIMEOUT);
  });


  /**
   * On touch
   */
  el.addEventListener('touchstart', e => {
    hideHint(explanationEl);
    showHint(scrollDownEl);
  });

  el.addEventListener('touchmove', e => {
    e.preventDefault(); // Prevent 'scrolling'

    addPoint(e.targetTouches[0]);
    hideHint(hintEl);
  });

  /**
   * When done touching
   */
  el.addEventListener('touchend', e => {
    setTimeout(function() {
      showHint(hintEl);
    }, TIMEOUT);
  });

  /**
   * Push points to array
   */
  function addPoint(e) {
    pD.push([e.clientX, e.clientY]);
    if (pD.length > amountPoints) {
      removePoint();
    };
    pU();
  };

  /**
   * Tween it
   */
  const ticker = new com.greensock.Ticker(45); // tick 45 times per second, RAF
  ticker.addEventListener('tick', removePoint);

  /**
   * Move points
   */
  function mP(point1, point2, f) {
    return [
      point1[0]+(point2[0]-point1[0])*f,point1[1]+(point2[1]-point1[1])*f
    ]
  };

  /**
   * Remove points from array
   */
  function removePoint() {
    if (pD.length > 1) {
      pD.shift();
      pU();
    }
  };

  /**
   * Set attr on SVG el
   */
  function pU() {
    var nP = pD.length > 1 ? pM : pM+pDZ;
    for(var L = pD.length-1, j=0; j < L; j++) {
      if (j!=0) {
        var P2 = mP(pD[j], pD[j+1], .5); // .5
        nP += pQ + pD[j][0] + pCo + pD[j][1] + pSp + P2[0] + pCo + P2[1];
      } else {
        nP += pSp + pD[j][0] + pCo + pD[j][1];
      }
    }
    path.setAttribute('d', nP);
  }
};

export const enhancer = el => {
  const scrollDownEl = el.querySelector('.hidden-gems-discovery-header__scroll-down');
  const explanationEl = el.querySelector('.hidden-gems-discovery-header__explanation');
  const html = document.querySelector('html');

  toggleScrollDownEl(scrollDownEl);
  toggleExplanationEl(scrollDownEl, explanationEl, html);
  trailingCursor(el, scrollDownEl, explanationEl);
  manipulateDocument(el, html);
};
