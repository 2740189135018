const $ = jQuery.noConflict();

const updateHomepageHeaderColor = () => {
  if ($(document).scrollTop() > 0) {
    $('.header--homepage').removeClass('is-transparent');
  } else {
    $('.header--homepage').addClass('is-transparent');
  }
};

export const enhancer = () => {
  if ($('.header--homepage').length > 0 && $(window).width() >= 1308) {
    updateHomepageHeaderColor();

    $(window).scroll(updateHomepageHeaderColor);

    $(window).resize(updateHomepageHeaderColor);
  }
};
