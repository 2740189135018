import SweetScroll from 'sweet-scroll';

/*
 * Smooth scroll to # elements
 */
export const handler = (element, e) => {

  e.preventDefault();
  const target = element.getAttribute('href');
  if (!document.querySelector(target)) {
    return;
  }
  const sweetScroll = new SweetScroll({
    duration: 700,
    easing: 'easeInOutQuad',
    offset: getOffset(element)
  });
  sweetScroll.to(target);
};

const getOffset = (element) => {
  return 0;
};
