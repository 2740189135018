import GMaps from 'gmaps';

const $ = jQuery.noConflict();

export const enhancer = () => {

  var mapZoom = 3;

  if ($(window).width() === 768) {
    mapZoom = 2;
  } else if ($(window).width() <= 767) {
    mapZoom = 1;
  }

  const map = new GMaps({
    el: '.maps',
    lat: 30,
    lng: 0,
    zoom: mapZoom,
    // zoomControl: true,
    zoomControlOpt: {
      style: 'SMALL',
      position: 'TOP_RIGHT'
    },
    // panControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    // overviewMapControl: false
    scrollwheel: false,
    fullscreenControl: false,
  });

  var mapColor = '#574b70';

  if ($('.maps--red').length > 0) {
    mapColor = '#FA5149';
  }

  var styles = [
    {
      'featureType': 'all',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'all',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'visibility': 'off'
        },
        {
          'color': mapColor
        }
      ]
    },
    {
      'featureType': 'all',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'all',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off'
        },
        {
          'hue': '#5300ff'
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'all',
      'stylers': [
        {
          'hue': '#000000'
        },
        {
          'lightness': -100
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'administrative',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'on'
        },
        {
          'color': '#322e34'
        }
      ]
    },
    {
      'featureType': 'administrative.country',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'administrative.locality',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'administrative.neighborhood',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        },
        {
          'color': '#09ff99'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'all',
      'stylers': [
        {
          'color': '#ff0000'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry',
      'stylers': [
        {
          'saturation': '-33'
        },
        {
          'lightness': -3
        },
        {
          'visibility': 'on'
        },
        {
          'color': mapColor
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry.stroke',
      'stylers': [
        {
          'hue': '#00ff32'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'labels',
      'stylers': [
        {
          'hue': '#000000'
        },
        {
          'saturation': -100
        },
        {
          'lightness': -100
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'landscape.man_made',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        },
        {
          'color': '#ff0000'
        }
      ]
    },
    {
      'featureType': 'landscape.natural.landcover',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'simplified'
        },
        {
          'color': mapColor
        }
      ]
    },
    {
      'featureType': 'landscape.natural.landcover',
      'elementType': 'labels.text',
      'stylers': [
        {
          'hue': '#ff0000'
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'landscape.natural.terrain',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        },
        {
          'color': '#ff0000'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'all',
      'stylers': [
        {
          'hue': '#ff0000'
        },
        {
          'saturation': -100
        },
        {
          'lightness': -100
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'all',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'lightness': 26
        },
        {
          'visibility': 'off'
        },
        {
          'color': '#322e34'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels',
      'stylers': [
        {
          'hue': '#ff0000'
        },
        {
          'saturation': -100
        },
        {
          'lightness': 100
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'all',
      'stylers': [
        {
          'hue': '#ff0000'
        },
        {
          'saturation': -100
        },
        {
          'lightness': 100
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'visibility': 'on'
        },
        {
          'color': '#322e34'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'labels',
      'stylers': [
        {
          'hue': '#000000'
        },
        {
          'lightness': -100
        },
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'lightness': 100
        },
        {
          'visibility': 'on'
        },
        {
          'color': '#322e34'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels',
      'stylers': [
        {
          'hue': '#000000'
        },
        {
          'saturation': -100
        },
        {
          'lightness': -100
        },
        {
          'visibility': 'off'
        }
      ]
    }
  ];

  map.addStyle({
    styledMapName: 'Styled Map',
    styles: styles,
    mapTypeId: 'map_style'
  });

  map.setStyle('map_style');

  if (!window.mapMarkers) {
    return;
  }

  $.each(window.mapMarkers, (index, marker) => {
    map.addMarker(marker);
  });

};
